<template>
  <div v-if="!!text">
    <div class="flex items-center">
      <span class="material-icons text-blue-600 w-[24px] h-[24px] mr-4"
        >done</span
      >
      <p v-if="!isHtml">
        {{ computedText }}
      </p>
      <p v-else v-html="computedText"></p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  isHtml: { type: Boolean },
});

const computedText = computed(() => {
  return props.text[0].toUpperCase() + props.text.slice(1);
});
</script>
