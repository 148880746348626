<template>
	<div
		class="rounded-xl border-radial-1-card border py-6 px-4 flex flex-col justify-between"
		:class="{
			'plan-card-gradient-bg': showGradient,
		}"
	>
		<div>
			<span class="text-sm">
				{{ $t('planCard.includedWith') }}
			</span>
			<div class="flex items-center mt-2 mb-6">
				<img
					:src="`/web/icons/plans/${plan.englishName?.toLowerCase()}.svg`"
					class="w-[24px] h-[24px]"
					alt="Icon"
				/>
				<h1 class="text-2xl font-bold ml-2">{{ plan.name }}</h1>
			</div>
			<div class="space-y-4">
				<DefaultPlanCardFeatureText :text="creditsBenefitText" :isHtml="true" />
				<DefaultPlanCardFeatureText v-for="item in (plan.features as string[])" :key="item" :text="item" />
				<DefaultPlanCardFeatureText
					:text="
						$t('planCard.averageAvatarGeneration', {
							count: getAvatarGenerationCount(),
						})
					"
					:isHtml="true"
				/>
			</div>
			<p
				class="mt-6 text-sm"
				v-html="
					$t('planCard.generalWarning', {
						avatarsCount: plan.averageAvatarGenerationCount,
					})
				"
			></p>
		</div>

		<DefaultButton :isLoading="isCreatingSession" class="mt-11" @click="handleSubscribe" v-if="showButton">
			{{ $t('planCard.startNow') }} {{ $formatCurrency(plan.price) }}/{{ $t('common.month') }}
		</DefaultButton>
		<slot name="footer"></slot>
	</div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import type { IPlanPopulated } from '~/types/models/plan.interface'

const { user: loggedUser } = useUserStore()
const isCreatingSession = ref<boolean>(false)
const { t } = useI18n()

const props = defineProps<{
	plan: IPlanPopulated
	showButton: boolean
	showGradient: boolean
}>()

const handleSubscribe = async () => {
	isCreatingSession.value = true
	const planName = props.plan.englishName.toLowerCase()

	if (!loggedUser) {
		$captureEvent(`plan_card_subscribe_intent_${planName}_not_logged_in`)
		navigateTo('/create-account?redirectTo=/checkout/plans')
		return
	}

	$captureEvent(`plan_card_subscribe_click_${planName}`)

	try {
		navigateTo(`/checkout/payment-select?id=${props.plan._id}`)
		$captureEvent('plans_subscribe_intent')
		// sendTiktokInitCheckoutEvent(this.loggedUser);
	} catch (error) {
		$sentryCaptureException(error)
		$captureEvent('plan_card_subscribe_error')

		$toast({
			type: 'error',
			title: t('common.toastTitle.error'),
			message: t('checkoutPlans.errorToastMessage'),
		})
	}

	isCreatingSession.value = true
}
const getAvatarGenerationCount = () => {
	const value = props.plan.averageAvatarGenerationCount

	if (value >= 1000) {
		return Math.floor(value / 1000) + 'K+'
	}

	return value
}

const creditsBenefitText = computed(() => {
	if (!props.plan) {
		return ''
	}

	const baseText = `<strong>${props.plan.points} ${t('common.realityPoints')}</strong>`

	return props.plan.bonusPoints
		? `${baseText} (<strong>+${props.plan.bonusPoints} ${t('common.realityPoints')}</strong>)`
		: baseText
})
</script>

<style>
.plan-card-gradient-bg {
	background: linear-gradient(to bottom, rgba(11, 9, 33, 0.4), rgba(2, 7, 13, 0));
}
</style>
